<script>
import Layout from "../../layouts/main";
import AssistantLayout from '../../layouts/assistantLayout';
import errorHandler from '../../../helpers/errorHandler';
// import moment from 'moment';

/**
 * Starter component
 */
export default {
  page: {
    title: "Assistant details",
  },
  name: "details",
  components:{
    Layout,
    AssistantLayout
  }, 
  async created(){
    await this.$store.dispatch('assistant/getAssistantInfo',this.$route.params.assistant).then(()=>{
        this.profileData = this.$store.getters['assistant/getAssistant'];
    }).catch( err=> {
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
  },
  data() {
    return {
      profileData:{}
    };
  },
//   filters:{
//     formatDate(value){
//       if(value){
//         return moment(String(value)).format('hh:mm A DD/MM/YYYY')
//       }
//     }
//   }
};
</script>

<template>
  <Layout>
    <AssistantLayout :assistant="profileData">
        <div>
            <h5 class="mb-3 text-uppercase bg-light p-2">
                <i class="mdi mdi-account-circle mr-1 "></i> Basic Info
            </h5>
            <table class="table table-borderless table-sm">
            <tbody class="row">
                <tr class="col-md-6">
                <th scope="row">Assistant Name:</th>
                <td v-if="profileData.name">
                    {{ profileData.name}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Username:</th>
                <td v-if="profileData.user_name">
                    {{ profileData.user_name}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Phone:</th>
                <td v-if="profileData.phone_number">
                    {{ profileData.phone_number}}
                </td>
                </tr>
            </tbody>
            </table>
            <b-container class="bv-example-row bv-example-row-flex-cols">
            <b-row>
                <b-col class="col-md-2 offset-md-10 offset-0">
                <router-link :to="'/assistants/'+this.$route.params.assistant+'/edit'">
                    <b-button variant="outline-primary" pill>
                    <i class="fas fa-pen mr-1"></i>
                    Edit 
                    </b-button>
                </router-link>
                </b-col>
            </b-row>
            </b-container>
            
        </div>
    </AssistantLayout>
  </Layout>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
